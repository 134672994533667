import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    static targets = [ 'optionsTag', 'toggleOptionsButton' ]

    connect() {

    }

    toggleOptions() {
        this.optionsTagTarget.classList.toggle('hide')
        if (this.optionsTagTarget.classList.contains('hide')) {
            this.toggleOptionsButtonTarget.innerHTML = 'mehr'
        } else {
            this.toggleOptionsButtonTarget.innerHTML = 'weniger'
        }
    }
}